.wd-msg-prompt[data-v-4a388a1b] {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 0.2rem;
  color: #444;
}
.wd-msg-prompt .tips_icon[data-v-4a388a1b] {
  font-size: 0.88rem;
  color: #1890ff;
}
.wd-msg-prompt .open-btn[data-v-4a388a1b] {
  cursor: pointer;
  display: inline-block;
  padding: 0.08rem 0.24rem;
  font-size: 0.2rem;
  background: #1890ff;
  border-radius: 0.06rem;
  color: #fff;
  margin: 0.5rem auto 0.8rem;
}
.wd-msg-prompt .open-btn .anticon[data-v-4a388a1b] {
  font-size: 0.2rem;
  margin-left: 0.05rem;
}
